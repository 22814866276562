"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var hooks_1 = require("../../hooks");
function ContextualSaveBar(_a) {
    var _b = _a.discardAction, discardAction = _b === void 0 ? {} : _b, _c = _a.saveAction, saveAction = _c === void 0 ? {} : _c, fullWidth = _a.fullWidth, leaveConfirmationDisable = _a.leaveConfirmationDisable, visible = _a.visible;
    var _d = hooks_1.useContextualSaveBar(), show = _d.show, hide = _d.hide, saveActionSetOptions = _d.saveAction.setOptions, discardActionSetOptions = _d.discardAction.setOptions;
    react_1.useEffect(function () {
        saveActionSetOptions(saveAction);
    }, [saveAction]);
    react_1.useEffect(function () {
        discardActionSetOptions(discardAction);
    }, [discardAction]);
    react_1.useEffect(function () {
        if (visible) {
            show({ fullWidth: fullWidth, leaveConfirmationDisable: leaveConfirmationDisable });
        }
        else {
            hide();
        }
    }, [fullWidth, leaveConfirmationDisable, visible]);
    return null;
}
exports.default = ContextualSaveBar;
