"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var AppLink_1 = require("@shopify/app-bridge/actions/Link/AppLink");
var NavigationMenu_1 = require("@shopify/app-bridge/actions/Menu/NavigationMenu");
var useAppBridge_1 = require("../../useAppBridge");
var Provider_1 = require("../Provider/Provider");
function defaultMatcher(link, location) {
    var pathname = typeof location === 'string' ? new URL(location).pathname : location.pathname;
    return link.destination.replace(/\/$/, '') === pathname.replace(/\/$/, '');
}
function NavigationMenu(_a) {
    var navigationLinks = _a.navigationLinks, _b = _a.matcher, matcher = _b === void 0 ? defaultMatcher : _b;
    var app = useAppBridge_1.useAppBridge();
    var _c = react_1.useState(), items = _c[0], setItems = _c[1];
    var locationOrHref = (Provider_1.useRouter() || {}).location;
    var location = react_1.useMemo(function () { return locationOrHref !== null && locationOrHref !== void 0 ? locationOrHref : window.location; }, [locationOrHref]);
    react_1.useEffect(function () {
        var items = navigationLinks.map(function (link) { return AppLink_1.create(app, link); });
        setItems(items);
    }, [app, JSON.stringify(navigationLinks)]);
    var activeLink = react_1.useMemo(function () {
        var activeLinkIndex = (items || []).findIndex(function (link) {
            return matcher(link, location);
        });
        if (activeLinkIndex >= 0) {
            return items === null || items === void 0 ? void 0 : items[activeLinkIndex];
        }
    }, [items, matcher, location]);
    react_1.useEffect(function () {
        // Skip creating the navigation menu on initial render
        if (!items) {
            return;
        }
        /**
         * There isn't any advantage to keeping around a consistent instance of
         * the navigation menu as when we create a new one it results in
         * the same UPDATE action to be dispatched
         */
        NavigationMenu_1.create(app, { items: items, active: activeLink });
    }, [items, activeLink, app]);
    return null;
}
exports.default = NavigationMenu;
