"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var Button_1 = require("@shopify/app-bridge/actions/Button");
var TitleBar_1 = require("@shopify/app-bridge/actions/TitleBar");
var ButtonGroup_1 = require("@shopify/app-bridge/actions/ButtonGroup");
var transformers_1 = require("../../utilities/transformers");
var useAppBridge_1 = require("../../useAppBridge");
function TitleBar(props) {
    var app = useAppBridge_1.useAppBridge();
    var currentProps = react_1.useRef();
    var titleBar = react_1.useMemo(function () {
        /**
         * We purposely don't pass buttons here as they will be replaced
         * by the subsequent useEffect to call `set` to update the options.
         * If we pass them in here button subscriptions will be created without
         * being cleaned up.
         */
        return TitleBar_1.create(app, {});
    }, [app]);
    /**
     * All option updates are handled in this useEffect so they can be cleaned up.
     */
    react_1.useEffect(function () {
        var _a;
        var propsChanged = JSON.stringify(currentProps.current) !== JSON.stringify(props);
        currentProps.current = props;
        if (propsChanged) {
            titleBar.set(transformProps(app, props));
        }
        else {
            /**
             * We manually update the button callbacks when no props have changed aside from the
             * callbacks to avoid sending an unnecessary UPDATE action to the host
             */
            var primaryAction = props.primaryAction, secondaryActions = props.secondaryActions, actionGroups = props.actionGroups, breadcrumbs = props.breadcrumbs;
            var breadcrumb = Array.isArray(breadcrumbs)
                ? breadcrumbs[breadcrumbs.length - 1]
                : breadcrumbs;
            updateButton(breadcrumb, titleBar.options.breadcrumbs);
            updateButton(primaryAction, (_a = titleBar.options.buttons) === null || _a === void 0 ? void 0 : _a.primary);
            updateSecondaryActions(titleBar, secondaryActions);
            updateActionGroups(titleBar, actionGroups);
        }
        return function () {
            titleBar.unsubscribe();
        };
    }, [titleBar, props]);
    return null;
}
exports.default = TitleBar;
function updateSecondaryActions(titleBar, secondaryActions) {
    var _a, _b;
    var secondaryButtons = ((_b = (_a = titleBar.options.buttons) === null || _a === void 0 ? void 0 : _a.secondary) === null || _b === void 0 ? void 0 : _b.filter(function (button) { return !ButtonGroup_1.isGroupedButton(button); })) || [];
    secondaryButtons === null || secondaryButtons === void 0 ? void 0 : secondaryButtons.forEach(function (secondaryButton, index) {
        return updateButton(secondaryActions === null || secondaryActions === void 0 ? void 0 : secondaryActions[index], 
        // This needs to be casted as the React TitleBar component doesn't accept button groups for secondary actions
        secondaryButton);
    });
}
function updateActionGroups(titleBar, actionGroups) {
    var _a, _b;
    var actionGroupButtons = ((_b = (_a = titleBar.options.buttons) === null || _a === void 0 ? void 0 : _a.secondary) === null || _b === void 0 ? void 0 : _b.filter(ButtonGroup_1.isGroupedButton)) ||
        [];
    actionGroupButtons === null || actionGroupButtons === void 0 ? void 0 : actionGroupButtons.forEach(function (actionBroupButton, index) {
        var actionGroup = actionGroups === null || actionGroups === void 0 ? void 0 : actionGroups[index];
        if (!actionGroup) {
            return;
        }
        actionBroupButton.options.buttons.forEach(function (nestedButton, nestedIndex) {
            return updateButton(actionGroup.actions[nestedIndex], nestedButton);
        });
    });
}
function transformProps(app, _a) {
    var actionGroups = _a.actionGroups, breadcrumbs = _a.breadcrumbs, primaryAction = _a.primaryAction, secondaryActions = _a.secondaryActions, title = _a.title;
    var breadcrumb = Array.isArray(breadcrumbs) ? breadcrumbs[breadcrumbs.length - 1] : breadcrumbs;
    return {
        title: title,
        buttons: transformers_1.transformActions(app, {
            primaryAction: primaryAction,
            secondaryActions: secondaryActions,
            actionGroups: actionGroups,
        }),
        breadcrumbs: breadcrumb ? transformBreadcrumb(app, breadcrumb) : undefined,
    };
}
function transformBreadcrumb(app, breadcrumb, updateBreadcrumb) {
    var button = updateBreadcrumb ||
        Button_1.create(app, {
            label: breadcrumb.content || '',
        });
    updateButton(breadcrumb, button);
    return button;
}
function updateButton(actionProps, button) {
    if (!actionProps || !button) {
        return;
    }
    var redirect = transformers_1.generateRedirect(button.app, actionProps.url, actionProps.target, actionProps.external);
    if (redirect) {
        button.subscribe(Button_1.Action.CLICK, redirect, button);
    }
    if (actionProps === null || actionProps === void 0 ? void 0 : actionProps.onAction) {
        button.subscribe(Button_1.Action.CLICK, actionProps.onAction, button);
    }
}
