"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceType = exports.ActionVerb = void 0;
var react_1 = require("react");
var ResourcePicker_1 = require("@shopify/app-bridge/actions/ResourcePicker");
Object.defineProperty(exports, "ActionVerb", { enumerable: true, get: function () { return ResourcePicker_1.ActionVerb; } });
Object.defineProperty(exports, "ResourceType", { enumerable: true, get: function () { return ResourcePicker_1.ResourceType; } });
var useAppBridge_1 = require("../../useAppBridge");
/**
 * ResourcePicker component
 *
 * @remarks
 * React component which wraps the Shopify App Bridge ResourcePicker action.
 *
 * @public
 */
function ResourcePicker(_a) {
    var open = _a.open, resourceType = _a.resourceType, onSelection = _a.onSelection, onCancel = _a.onCancel, allowMultiple = _a.allowMultiple, selectMultiple = _a.selectMultiple, props = __rest(_a, ["open", "resourceType", "onSelection", "onCancel", "allowMultiple", "selectMultiple"]);
    var options = react_1.useMemo(function () { return (__assign(__assign({}, props), { selectMultiple: selectMultiple !== null && selectMultiple !== void 0 ? selectMultiple : allowMultiple })); }, [allowMultiple, props, selectMultiple]);
    var app = useAppBridge_1.useAppBridge();
    var isUnmountedRef = react_1.useRef(false);
    react_1.useEffect(function () { return function () {
        isUnmountedRef.current = true;
    }; }, []);
    var openRef = react_1.useRef(false);
    var optionsRef = react_1.useRef(options);
    var picker = react_1.useMemo(function () {
        return ResourcePicker_1.create(app, {
            resourceType: ResourcePicker_1.ResourceType[resourceType],
            options: optionsRef.current,
        });
    }, [app, resourceType]);
    react_1.useEffect(function () {
        openRef.current = false;
        return function () {
            if (openRef.current && isUnmountedRef.current) {
                picker.dispatch(ResourcePicker_1.Action.CANCEL);
            }
        };
    }, [picker]);
    var focusReturnPointRef = react_1.useRef(null);
    var storeFocusReturnPoint = react_1.useCallback(function () {
        if (document.activeElement instanceof HTMLElement) {
            focusReturnPointRef.current = document.activeElement;
        }
    }, []);
    var applyFocusReturnPoint = react_1.useCallback(function () {
        var focusReturnPoint = focusReturnPointRef.current;
        focusReturnPointRef.current = null;
        if (focusReturnPoint && document.contains(focusReturnPoint)) {
            focusReturnPoint.focus();
        }
    }, []);
    react_1.useEffect(function () {
        if (open === openRef.current)
            return;
        openRef.current = open;
        if (open) {
            picker.dispatch(ResourcePicker_1.Action.OPEN);
            storeFocusReturnPoint();
        }
        else {
            picker.dispatch(ResourcePicker_1.Action.CLOSE);
            applyFocusReturnPoint();
        }
    }, [picker, open, storeFocusReturnPoint, applyFocusReturnPoint]);
    react_1.useEffect(function () {
        if (!onSelection)
            return;
        return picker.subscribe(ResourcePicker_1.Action.SELECT, function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            openRef.current = false;
            applyFocusReturnPoint();
            return onSelection.apply(void 0, args);
        });
    }, [picker, onSelection, applyFocusReturnPoint]);
    react_1.useEffect(function () {
        if (!onCancel)
            return;
        return picker.subscribe(ResourcePicker_1.Action.CANCEL, function () {
            openRef.current = false;
            applyFocusReturnPoint();
            return onCancel();
        });
    }, [picker, onCancel, applyFocusReturnPoint]);
    react_1.useEffect(function () {
        var shouldUpdate = JSON.stringify(options) !== JSON.stringify(optionsRef.current);
        if (!shouldUpdate)
            return;
        optionsRef.current = options;
        picker.set(options);
    }, [picker, options]);
    return null;
}
exports.default = ResourcePicker;
