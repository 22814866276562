"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigate = void 0;
var react_1 = require("react");
var Redirect_1 = require("@shopify/app-bridge/actions/Navigation/Redirect");
var useAppBridge_1 = require("../../useAppBridge");
var useNavigationHistory_1 = require("../useNavigationHistory");
function useNavigate() {
    var app = useAppBridge_1.useAppBridge();
    var history = useNavigationHistory_1.useNavigationHistory();
    var redirect = react_1.useMemo(function () {
        return Redirect_1.create(app);
    }, [app]);
    var handleRedirect = react_1.useCallback(function (to, options) {
        var url = Redirect_1.normalizeUrl(to);
        var isAppUrl = url.startsWith(app.localOrigin);
        var isHostUrl = url.startsWith(app.hostOrigin);
        var isRelative = url.startsWith('/');
        if (isAppUrl || isHostUrl || isRelative) {
            var path = Redirect_1.getRelativePath(url);
            if (isHostUrl || (options === null || options === void 0 ? void 0 : options.target) === 'new' || (options === null || options === void 0 ? void 0 : options.target) === 'host') {
                redirect.dispatch(Redirect_1.Action.ADMIN_PATH, {
                    path: path.replace(/^\/admin/, ''),
                    newContext: (options === null || options === void 0 ? void 0 : options.target) === 'new',
                });
                return;
            }
            if (((options === null || options === void 0 ? void 0 : options.target) === 'self' || !(options === null || options === void 0 ? void 0 : options.target)) && (options === null || options === void 0 ? void 0 : options.replace)) {
                history.replace({ pathname: path });
                return;
            }
            redirect.dispatch(Redirect_1.Action.APP, path);
            return;
        }
        redirect.dispatch(Redirect_1.Action.REMOTE, {
            url: url,
            newContext: (options === null || options === void 0 ? void 0 : options.target) === 'new',
        });
    }, [redirect, history]);
    return react_1.useCallback(function (to, options) {
        if (Redirect_1.isAdminSection(to)) {
            var convertedSection = __assign(__assign({}, to), { name: Redirect_1.ResourceType[to.name] });
            redirect.dispatch(Redirect_1.Action.ADMIN_SECTION, {
                section: convertedSection,
                newContext: (options === null || options === void 0 ? void 0 : options.target) === 'new',
            });
            return;
        }
        handleRedirect(to, options);
    }, [handleRedirect, redirect]);
}
exports.useNavigate = useNavigate;
