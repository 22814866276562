"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuthenticatedFetch = void 0;
var react_1 = require("react");
var utilities_1 = require("@shopify/app-bridge/utilities");
var useAppBridge_1 = require("../../useAppBridge");
/**
 * useAuthenticatedFetch hook
 *
 * @public
 * @remarks
 * React hook that wraps the Shopify App Bridge authenticatedFetch method.
 *
 * @param options - Options for the hook
 */
function useAuthenticatedFetch(options) {
    if (options === void 0) { options = undefined; }
    var app = useAppBridge_1.useAppBridge();
    var fetchFunction = react_1.useMemo(function () { return utilities_1.authenticatedFetch(app, options); }, [app, options]);
    return fetchFunction;
}
exports.useAuthenticatedFetch = useAuthenticatedFetch;
