"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var Modal_1 = require("@shopify/app-bridge/actions/Modal");
var transformers_1 = require("../../utilities/transformers");
var useAppBridge_1 = require("../../useAppBridge");
var useOnceEffect_1 = require("../../hooks/useOnceEffect");
/**
 * Modal component
 *
 * @remarks
 * React component which wraps the Shopify App Bridge Modal action.
 *
 * @public
 */
function Modal(props) {
    var app = useAppBridge_1.useAppBridge();
    var focusReturnPoint = react_1.useRef(null);
    // Initialize with open: false so the open action will dispatch on initial load
    var prevProps = react_1.useRef({ open: false });
    var open = props.open;
    var isUnmounted = react_1.useRef(false);
    var modal = react_1.useMemo(function () {
        /**
         * We purposely don't pass buttons here as they will be replaced
         * by the subsequent useEffect to call `set` to update the options.
         * If we pass them in here button subscriptions will be created without
         * being cleaned up.
         */
        var primaryAction = props.primaryAction, secondaryActions = props.secondaryActions, rest = __rest(props, ["primaryAction", "secondaryActions"]);
        return Modal_1.create(app, transformProps(app, rest));
    }, [app]);
    /**
     * All option updates are handled in this useEffect to avoid race conditions.
     * For example, we need to call `modal.unsubscribe` to clean up button subscriptions
     * but we calling it will also unsubscribe the onClose handler. Therefore we want
     * to ensure all callbacks are handled in a single useEffect with a single clean up
     * call to unsubscribe.
     */
    react_1.useEffect(function () {
        if (isUnmounted.current) {
            prevProps.current = props;
            return;
        }
        var wasOpen = prevProps.current.open;
        var openUpdated = wasOpen !== open;
        /** We only call `set` to update the options if the modal is open.
         * This is because while the modal is closed updates don't take effect on screen.
         */
        if (open) {
            var transformedProps = transformProps(app, props, wasOpen);
            /** We skip dispatching the update action if we're about to update the open state.
             * This is because the OPEN action will always sends the updated options
             * so we don't need to send it twice.
             */
            var shouldSendUpdate = !openUpdated;
            if (isIframeModal(transformedProps)) {
                modal.set(transformedProps, shouldSendUpdate);
            }
            else {
                modal.set(transformedProps, shouldSendUpdate);
            }
        }
        if (openUpdated) {
            if (open) {
                modal.dispatch(Modal_1.Action.OPEN);
                focusReturnPoint.current = document.activeElement;
            }
            else {
                modal.dispatch(Modal_1.Action.CLOSE);
                if (focusReturnPoint.current != null && document.contains(focusReturnPoint.current)) {
                    focusReturnPoint.current.focus();
                    focusReturnPoint.current = null;
                }
            }
        }
        if (props.onClose != null) {
            modal.subscribe(Modal_1.Action.CLOSE, props.onClose);
        }
        prevProps.current = props;
        return function () {
            // This is important to clean up previous buttons and onClose subscriptions
            modal.unsubscribe();
        };
    }, [props, open]);
    useOnceEffect_1.useOnceEffect(function () {
        return function () {
            if (prevProps.current.open) {
                modal.dispatch(Modal_1.Action.CLOSE);
            }
        };
    }, []);
    return null;
}
function isIframeModal(options) {
    return (typeof options.url === 'string' ||
        typeof options.path === 'string');
}
function transformProps(app, props, wasOpen) {
    var title = props.title, size = props.size, message = props.message, src = props.src, primaryAction = props.primaryAction, secondaryActions = props.secondaryActions, loading = props.loading;
    var safeSize = size == null ? undefined : Modal_1.Size[size];
    var srcPayload = {};
    if (src != null) {
        if (src.match('^https?://')) {
            srcPayload.url = src;
        }
        else {
            srcPayload.path = src;
        }
    }
    return __assign(__assign({ title: title, message: message, size: safeSize }, srcPayload), { footer: {
            buttons: transformers_1.transformActions(app, {
                primaryAction: primaryAction,
                secondaryActions: secondaryActions,
            }),
        }, loading: wasOpen ? undefined : loading });
}
exports.default = Modal;
