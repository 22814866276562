"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToast = exports.DEFAULT_TOAST_DURATION = void 0;
var react_1 = require("react");
var Toast_1 = require("@shopify/app-bridge/actions/Toast");
var useAppBridge_1 = require("../../useAppBridge");
exports.DEFAULT_TOAST_DURATION = 5000;
/**
 * useToast hook
 *
 * @remarks
 * React hook which wraps the Shopify App Bridge Toast action.
 *
 * @public
 */
function useToast() {
    var app = useAppBridge_1.useAppBridge();
    var toastList = react_1.useRef([]);
    var show = react_1.useCallback(function (content, options) {
        var toast = Toast_1.create(app, {
            message: content,
            isError: options === null || options === void 0 ? void 0 : options.isError,
            duration: (options === null || options === void 0 ? void 0 : options.duration) || exports.DEFAULT_TOAST_DURATION,
            action: options === null || options === void 0 ? void 0 : options.action,
        });
        toast.dispatch(Toast_1.Action.SHOW);
        toastList.current.push(toast);
        toast.subscribe(Toast_1.Action.CLEAR, function () {
            var _a;
            (_a = options === null || options === void 0 ? void 0 : options.onDismiss) === null || _a === void 0 ? void 0 : _a.call(options);
            toastList.current.splice(toastList.current.indexOf(toast), 1);
            toast.unsubscribe();
        });
        toast.subscribe(Toast_1.Action.ACTION, function () {
            var _a, _b;
            (_b = (_a = options === null || options === void 0 ? void 0 : options.action) === null || _a === void 0 ? void 0 : _a.onAction) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
    }, [app]);
    react_1.useEffect(function () {
        return function () {
            toastList.current.forEach(function (toast) { return toast === null || toast === void 0 ? void 0 : toast.unsubscribe(); });
        };
    }, []);
    return { show: show };
}
exports.useToast = useToast;
