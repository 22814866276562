"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var Picker_1 = require("@shopify/app-bridge/actions/Picker");
var useAppBridge_1 = require("../../useAppBridge");
function Picker(_a) {
    var open = _a.open, onCancel = _a.onCancel, onSelect = _a.onSelect, onSearch = _a.onSearch, onLoadMore = _a.onLoadMore, options = __rest(_a, ["open", "onCancel", "onSelect", "onSearch", "onLoadMore"]);
    var app = useAppBridge_1.useAppBridge();
    var isUnmountedRef = react_1.useRef(false);
    react_1.useEffect(function () { return function () {
        isUnmountedRef.current = true;
    }; }, []);
    var openRef = react_1.useRef(false);
    var optionsRef = react_1.useRef(options);
    var picker = react_1.useMemo(function () { return Picker_1.create(app, optionsRef.current); }, [app]);
    react_1.useEffect(function () {
        openRef.current = false;
        return function () {
            if (openRef.current && isUnmountedRef.current) {
                picker.dispatch(Picker_1.Action.CANCEL);
            }
        };
    }, [picker]);
    react_1.useEffect(function () {
        if (open === openRef.current)
            return;
        openRef.current = open;
        if (open) {
            picker.dispatch(Picker_1.Action.OPEN);
        }
        else {
            picker.dispatch(Picker_1.Action.CANCEL);
        }
    }, [picker, open]);
    react_1.useEffect(function () {
        if (!onSelect)
            return;
        return picker.subscribe(Picker_1.Action.SELECT, function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            openRef.current = false;
            return onSelect.apply(void 0, args);
        });
    }, [picker, onSelect]);
    react_1.useEffect(function () {
        if (!onCancel)
            return;
        return picker.subscribe(Picker_1.Action.CANCEL, function () {
            openRef.current = false;
            return onCancel();
        });
    }, [picker, onCancel]);
    react_1.useEffect(function () {
        if (!onSearch)
            return;
        return picker.subscribe(Picker_1.Action.SEARCH, onSearch);
    }, [picker, onSearch]);
    react_1.useEffect(function () {
        if (!onLoadMore)
            return;
        return picker.subscribe(Picker_1.Action.LOAD_MORE, onLoadMore);
    }, [picker, onLoadMore]);
    react_1.useEffect(function () {
        var shouldUpdate = JSON.stringify(options) !== JSON.stringify(optionsRef.current);
        if (!shouldUpdate)
            return;
        optionsRef.current = options;
        picker.set(options, openRef.current);
    }, [picker, options]);
    return null;
}
exports.default = Picker;
