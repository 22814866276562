var gray = {
  '1': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(255, 255, 255, " + alpha + ")";
  },
  '2': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(253, 253, 253, " + alpha + ")";
  },
  '3': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(250, 250, 250, " + alpha + ")";
  },
  '4': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(247, 247, 247, " + alpha + ")";
  },
  '5': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(243, 243, 243, " + alpha + ")";
  },
  '6': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(241, 241, 241, " + alpha + ")";
  },
  '7': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(235, 235, 235, " + alpha + ")";
  },
  '8': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(227, 227, 227, " + alpha + ")";
  },
  '9': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(212, 212, 212, " + alpha + ")";
  },
  '10': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(204, 204, 204, " + alpha + ")";
  },
  '11': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(181, 181, 181, " + alpha + ")";
  },
  '12': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(138, 138, 138, " + alpha + ")";
  },
  '13': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(97, 97, 97, " + alpha + ")";
  },
  '14': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(74, 74, 74, " + alpha + ")";
  },
  '15': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(48, 48, 48, " + alpha + ")";
  },
  '16': function _(alpha) {
    if (alpha === void 0) {
      alpha = '1';
    }
    return "rgba(26, 26, 26, " + alpha + ")";
  }
};
var azure = {
  '1': 'rgba(251, 253, 255, 1)',
  '2': 'rgba(242, 249, 255, 1)',
  '3': 'rgba(234, 244, 255, 1)',
  '4': 'rgba(224, 240, 255, 1)',
  '5': 'rgba(213, 235, 255, 1)',
  '6': 'rgba(202, 230, 255, 1)',
  '7': 'rgba(192, 225, 255, 1)',
  '8': 'rgba(168, 216, 255, 1)',
  '9': 'rgba(145, 208, 255, 1)',
  '10': 'rgba(81, 192, 255, 1)',
  '11': 'rgba(0, 148, 213, 1)',
  '12': 'rgba(0, 124, 180, 1)',
  '13': 'rgba(0, 103, 155, 1)',
  '14': 'rgba(0, 82, 124, 1)',
  '15': 'rgba(0, 58, 90, 1)',
  '16': 'rgba(0, 33, 51, 1)'
};
var blue = {
  '1': 'rgba(252, 253, 255, 1)',
  '2': 'rgba(246, 248, 255, 1)',
  '3': 'rgba(240, 242, 255, 1)',
  '4': 'rgba(234, 237, 255, 1)',
  '5': 'rgba(226, 231, 255, 1)',
  '6': 'rgba(219, 225, 255, 1)',
  '7': 'rgba(213, 220, 255, 1)',
  '8': 'rgba(197, 208, 255, 1)',
  '9': 'rgba(186, 199, 255, 1)',
  '10': 'rgba(151, 173, 255, 1)',
  '11': 'rgba(65, 136, 255, 1)',
  '12': 'rgba(0, 113, 233, 1)',
  '13': 'rgba(0, 91, 211, 1)',
  '14': 'rgba(0, 66, 153, 1)',
  '15': 'rgba(0, 46, 106, 1)',
  '16': 'rgba(0, 22, 51, 1)'
};
var cyan = {
  '1': 'rgba(250, 255, 254, 1)',
  '2': 'rgba(231, 252, 248, 1)',
  '3': 'rgba(215, 251, 243, 1)',
  '4': 'rgba(192, 249, 239, 1)',
  '5': 'rgba(165, 247, 233, 1)',
  '6': 'rgba(144, 244, 227, 1)',
  '7': 'rgba(146, 237, 222, 1)',
  '8': 'rgba(130, 227, 209, 1)',
  '9': 'rgba(116, 219, 200, 1)',
  '10': 'rgba(23, 199, 167, 1)',
  '11': 'rgba(19, 163, 137, 1)',
  '12': 'rgba(14, 132, 112, 1)',
  '13': 'rgba(8, 107, 90, 1)',
  '14': 'rgba(9, 83, 70, 1)',
  '15': 'rgba(6, 56, 47, 1)',
  '16': 'rgba(5, 36, 30, 1)'
};
var green = {
  '1': 'rgba(248, 255, 251, 1)',
  '2': 'rgba(227, 255, 237, 1)',
  '3': 'rgba(205, 254, 225, 1)',
  '4': 'rgba(180, 254, 210, 1)',
  '5': 'rgba(146, 254, 194, 1)',
  '6': 'rgba(99, 253, 176, 1)',
  '7': 'rgba(56, 250, 163, 1)',
  '8': 'rgba(53, 238, 155, 1)',
  '9': 'rgba(50, 225, 147, 1)',
  '10': 'rgba(46, 211, 137, 1)',
  '11': 'rgba(50, 160, 110, 1)',
  '12': 'rgba(41, 132, 90, 1)',
  '13': 'rgba(19, 111, 69, 1)',
  '14': 'rgba(12, 81, 50, 1)',
  '15': 'rgba(8, 61, 37, 1)',
  '16': 'rgba(9, 42, 27, 1)'
};
var magenta = {
  '1': 'rgba(255, 253, 255, 1)',
  '2': 'rgba(255, 245, 255, 1)',
  '3': 'rgba(253, 239, 253, 1)',
  '4': 'rgba(254, 231, 254, 1)',
  '5': 'rgba(252, 223, 252, 1)',
  '6': 'rgba(251, 215, 251, 1)',
  '7': 'rgba(251, 207, 251, 1)',
  '8': 'rgba(249, 190, 249, 1)',
  '9': 'rgba(248, 177, 248, 1)',
  '10': 'rgba(246, 141, 246, 1)',
  '11': 'rgba(225, 86, 225, 1)',
  '12': 'rgba(197, 48, 197, 1)',
  '13': 'rgba(159, 38, 159, 1)',
  '14': 'rgba(121, 26, 121, 1)',
  '15': 'rgba(86, 16, 86, 1)',
  '16': 'rgba(52, 6, 52, 1)'
};
var orange = {
  '1': 'rgba(255, 253, 250, 1)',
  '2': 'rgba(255, 247, 238, 1)',
  '3': 'rgba(255, 241, 227, 1)',
  '4': 'rgba(255, 235, 213, 1)',
  '5': 'rgba(255, 228, 198, 1)',
  '6': 'rgba(255, 221, 182, 1)',
  '7': 'rgba(255, 214, 164, 1)',
  '8': 'rgba(255, 200, 121, 1)',
  '9': 'rgba(255, 184, 0, 1)',
  '10': 'rgba(229, 165, 0, 1)',
  '11': 'rgba(178, 132, 0, 1)',
  '12': 'rgba(149, 111, 0, 1)',
  '13': 'rgba(124, 88, 0, 1)',
  '14': 'rgba(94, 66, 0, 1)',
  '15': 'rgba(65, 45, 0, 1)',
  '16': 'rgba(37, 26, 0, 1)'
};
var purple = {
  '1': 'rgba(253, 253, 255, 1)',
  '2': 'rgba(248, 247, 255, 1)',
  '3': 'rgba(243, 241, 255, 1)',
  '4': 'rgba(239, 236, 255, 1)',
  '5': 'rgba(233, 229, 255, 1)',
  '6': 'rgba(228, 222, 255, 1)',
  '7': 'rgba(223, 217, 255, 1)',
  '8': 'rgba(212, 204, 255, 1)',
  '9': 'rgba(199, 188, 255, 1)',
  '10': 'rgba(170, 149, 255, 1)',
  '11': 'rgba(148, 116, 255, 1)',
  '12': 'rgba(128, 81, 255, 1)',
  '13': 'rgba(113, 38, 255, 1)',
  '14': 'rgba(87, 0, 209, 1)',
  '15': 'rgba(59, 0, 147, 1)',
  '16': 'rgba(28, 0, 79, 1)'
};
var red = {
  '1': 'rgba(255, 251, 251, 1)',
  '2': 'rgba(255, 246, 246, 1)',
  '3': 'rgba(255, 237, 236, 1)',
  '4': 'rgba(254, 233, 232, 1)',
  '5': 'rgba(254, 226, 225, 1)',
  '6': 'rgba(254, 218, 217, 1)',
  '7': 'rgba(254, 211, 209, 1)',
  '8': 'rgba(254, 195, 193, 1)',
  '9': 'rgba(253, 176, 172, 1)',
  '10': 'rgba(253, 129, 122, 1)',
  '11': 'rgba(239, 77, 47, 1)',
  '12': 'rgba(229, 28, 0, 1)',
  '13': 'rgba(181, 38, 11, 1)',
  '14': 'rgba(142, 31, 11, 1)',
  '15': 'rgba(95, 21, 7, 1)',
  '16': 'rgba(47, 10, 4, 1)'
};
var rose = {
  '1': 'rgba(255, 253, 253, 1)',
  '2': 'rgba(255, 246, 248, 1)',
  '3': 'rgba(255, 239, 243, 1)',
  '4': 'rgba(255, 232, 238, 1)',
  '5': 'rgba(255, 225, 232, 1)',
  '6': 'rgba(255, 217, 227, 1)',
  '7': 'rgba(254, 209, 221, 1)',
  '8': 'rgba(254, 193, 210, 1)',
  '9': 'rgba(254, 181, 202, 1)',
  '10': 'rgba(254, 142, 177, 1)',
  '11': 'rgba(253, 75, 146, 1)',
  '12': 'rgba(227, 12, 118, 1)',
  '13': 'rgba(185, 7, 95, 1)',
  '14': 'rgba(141, 4, 72, 1)',
  '15': 'rgba(100, 2, 49, 1)',
  '16': 'rgba(62, 1, 28, 1)'
};
var yellow = {
  '1': 'rgba(255, 253, 246, 1)',
  '2': 'rgba(255, 248, 219, 1)',
  '3': 'rgba(255, 244, 191, 1)',
  '4': 'rgba(255, 239, 157, 1)',
  '5': 'rgba(255, 235, 120, 1)',
  '6': 'rgba(255, 230, 0, 1)',
  '7': 'rgba(247, 223, 0, 1)',
  '8': 'rgba(234, 211, 0, 1)',
  '9': 'rgba(225, 203, 0, 1)',
  '10': 'rgba(197, 178, 0, 1)',
  '11': 'rgba(153, 138, 0, 1)',
  '12': 'rgba(130, 117, 0, 1)',
  '13': 'rgba(105, 95, 0, 1)',
  '14': 'rgba(79, 71, 0, 1)',
  '15': 'rgba(51, 46, 0, 1)',
  '16': 'rgba(31, 28, 0, 1)'
};

export { azure, blue, cyan, gray, green, magenta, orange, purple, red, rose, yellow };
